import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { useMountEffect } from '../../../../hooks/v2';

export const Duration = {
  SHORT: 300,
  NORMAL: 1000,
  LONG: 2000,
};

UqScrollRevealAnimation.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,

  /** 
   * The time to wait before starting animation in ms 
   * (from 5 to 1000 ms) (see: https://github.com/mciastek/sal)
   */
  delay: PropTypes.number,

  /**
   * The elapsed time of the animation, converted into standard times
   * (from 200 to 2000 ms) (see: https://github.com/mciastek/sal)
   */
  duration: PropTypes.oneOf(Object.values(Duration)),

  /**
   * Eases the animation timing
   * (see: easings.net)˝
   */
  easing: PropTypes.string,
  onEnter: PropTypes.func,
  type: PropTypes.oneOf([
    'fade',
    'slide-down',
    'slide-left',
    'slide-right',
    'slide-up',
  ]),
};

UqScrollRevealAnimation.defaultProps = {
  onEnter: () => null,
};

export function UqScrollRevealAnimation(props) {
  const ref = useRef(null);

  useMountEffect(() => {
    ref.current?.addEventListener('sal:in', () => props.onEnter());
  });

  return (
    <div
      ref={ref}
      data-sal={props.type}
      data-sal-delay={props.delay}
      data-sal-duration={props.duration}
      data-sal-easing={props.easing}
    >
      {props.children}
    </div>
  );
}
