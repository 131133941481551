import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { UqFade, UqSlide, Duration } from '../../../components/v2/layout/animation';
import { UqTextHighlight } from '../typography';

import * as styles from './hero-stacked.module.scss';

const AnimationType = PropTypes.oneOf(['slide', 'fade']);

UqHeroStacked.propTypes = {
  animations: PropTypes.shape({
    image: AnimationType,
  }),
  classes: PropTypes.shape({
    image: PropTypes.string,
    title: PropTypes.string,
    root: PropTypes.string,
  }),
  image: PropTypes.node.isRequired,
  renderActions: PropTypes.func,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
};

UqHeroStacked.defaultProps = {
  animations: {
    image: 'slide',
  },
};

export function UqHeroStacked(props) {
  return (
    <section className={classNames(styles.root, props.classes?.root)}>
      <div className={styles.titleCol}>
        {renderFade((
          <>
            <h1 className={props.classes?.title}>
              <UqTextHighlight>
                {props.title}
              </UqTextHighlight>
            </h1>
            <p className="body-1">
              {props.subtitle}
            </p>
            {props.renderActions && props.renderActions()}
          </>
        ))}
      </div>
      <div className={classNames(styles.image, props.classes?.image)}>
        {props.animations.image === 'slide'
          ? renderSlide(props.image)
          : renderFade(props.image)}
      </div>
    </section>
  );

  function renderFade(content) {
    return (
      <UqFade duration={Duration.LONG}>
        {content}
      </UqFade>
    );
  }

  function renderSlide(content) {
    return (
      <UqSlide easing="ease-out-cubic">
        {content}
      </UqSlide>
    );
  }
}
